(function() {
    'use strict';

    angular
        .module('sfzPortalContribuinteGatewayApp')
        .factory('ForgotPassRoutes', ForgotPassRoutes);

    ForgotPassRoutes.$inject = ['$resource'];

    function ForgotPassRoutes ($resource) {
        var service = $resource('api/forgotpass/routes/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });

        return service;
    }

})();
