(function() {
    'use strict';

    angular
        .module('sfzPortalContribuinteGatewayApp')
        .factory('authInterceptor', authInterceptor);

    authInterceptor.$inject = ['$rootScope', '$q', '$location', '$localStorage', '$sessionStorage'];

    function authInterceptor ($rootScope, $q, $location, $localStorage, $sessionStorage) {
        var service = {
            request: request
        };

        return service;

        function request (config) {
            /*jshint camelcase: false */
            config.headers = config.headers || {};

            // Verifica se a URL começa com /sefaz-noticias/
            if (!config.url.startsWith('/sefaz-noticias/')) {
                var token = $localStorage.authenticationToken || $sessionStorage.authenticationToken;

                if (token) {
                    config.headers.Authorization = 'Bearer ' + token;
                }
            }
            
            return config;
        }
    }
})();
