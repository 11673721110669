(function () {
    "use strict";

    angular
        .module("sfzPortalContribuinteGatewayApp")
        .controller("HomeController", HomeController);

    HomeController.$inject = [
        "$scope",
        "Principal",
        "LoginService",
        "$state",
        "MensageriaService",
        "$uibModal",
        "$interval",
        "News",
    ];

    function HomeController(
        $scope,
        Principal,
        LoginService,
        $state,
        MensageriaService,
        $uibModal,
        $interval,
        News
    ) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.qtdMensagemNaoLidas = 0;

        vm.carouselCurrentIndex = 0;
        vm.carouselInterval = null;
        vm.carouselItems = [];
        vm.news = [];
        vm.baseUrl = "https://www.sefaz.al.gov.br";
        vm.cameFromFazendario = setOriginFlags();

        $scope.$on("authenticationSuccess", function () {
            getAccount();
        });

        getAccount();
        getNews();
        carouselStartInterval();

        function getNews() {
            News.query({})
                .$promise.then(function (response) {
                    //TODO: Filtrar por tag portal do contribuinte (1007)
                    vm.carouselItems = response.items.slice(0, 3);
                    vm.news = response.items.slice(3);
                })
                .catch(function (error) {
                    console.error("Erro ao carregar notícias:", error);
                });
        }

        function carouselOffsetIndex(index, offset) {
            return (
                (index + offset + vm.carouselItems.length) %
                vm.carouselItems.length
            );
        }
        vm.carouselGoTo = function (position) {
            carouselStartInterval();
            vm.carouselCurrentIndex = position;
        };

        vm.carouselMoveRight = function () {
            carouselStartInterval();
            vm.carouselCurrentIndex = carouselOffsetIndex(
                vm.carouselCurrentIndex,
                1
            );
        };

        vm.carouselMoveLeft = function () {
            carouselStartInterval();
            vm.carouselCurrentIndex = carouselOffsetIndex(
                vm.carouselCurrentIndex,
                -1
            );
        };

        function carouselStartInterval() {
            if (vm.carouselInterval) $interval.cancel(vm.carouselInterval);

            vm.carouselInterval = $interval(function () {
                vm.carouselMoveRight();
            }, 4000);
        }

        vm.timeSince = function (date) {
            var seconds = Math.floor((new Date() - new Date(date)) / 1000);

            var interval = seconds / 31536000;

            if (interval > 1) {
                return Math.floor(interval) + " anos";
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                return Math.floor(interval) + " meses";
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + " dias";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + " horas";
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + " minutos";
            }
            return Math.floor(seconds) + " segundos";
        };

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.exibirBotaoNotasFicaisEntrada =
                    exibirBotaoNotasFicaisEntrada();
                vm.isFazendario = Principal.isFazendario();
                if (Principal.isAuthenticated() && !vm.isFazendario) {
                    consultarMensagemNaoLidas();
                }
            });
        }
        function register() {
            $state.go("register");
        }

        function isExibeRelatorioContribuinte() {
            return Principal.isExibeRelatorioContribuinte();
        }

        function exibirBotaoNotasFicaisEntrada() {
            if (Principal.isFazendario()) {
                return Principal.isVerificaFazendarioPossuiPemissao(
                    "ROLE_MAL_REL_CONTRIBUINTE"
                );
            } else {
                return true;
            }
        }

        function consultarMensagemNaoLidas() {
            MensageriaService.consultarMensagemNaoLidas()
                .$promise.then(function (listaCriticidade) {
                    calcularTotalMensagensNaoLidas(listaCriticidade);
                    obterNivelCriticidade(listaCriticidade);
                })
                .catch(function (error) {
                    console.error("Erro ao consultar mensagens:", error);
                });
        }

        function obterNivelCriticidade(listaCriticidade) {
            MensageriaService.getNivelCriticidade()
                .$promise.then(function (codigoNivelCriticidade) {
                    verificarMensagensParaModal(
                        listaCriticidade,
                        codigoNivelCriticidade
                    );
                })
                .catch(function (error) {
                    console.error(
                        "Erro ao obter código de criticidade para abrir modal:",
                        error
                    );
                });
        }

        function calcularTotalMensagensNaoLidas(gruposDeMensagens) {
            vm.qtdMensagemNaoLidas = gruposDeMensagens.reduce(function (
                total,
                criticidade
            ) {
                return total + criticidade.qtdMsgNaoLida;
            },
            0);
        }

        function verificarMensagensParaModal(
            listaCriticidade,
            codigoNivelCriticidade
        ) {
            var temMensagemComCodigo = listaCriticidade.some(function (
                criticidade
            ) {
                return (
                    parseInt(criticidade.codigo, 10) >=
                        codigoNivelCriticidade && criticidade.qtdMsgNaoLida > 0
                );
            });

            if (temMensagemComCodigo) {
                abrirModalAlerta();
            }
        }

        function abrirModalAlerta() {
            $uibModal.open({
                templateUrl: "app/layouts/alert-modal/alert-modal.html",
                controller: "AlertModalController",
                backdrop: "static",
                keyboard: false,
            });
        }

        function setOriginFlags() {
            var fullUrl = window.location.href;
            var domain = fullUrl.split('//')[1].split('/')[0];
            return domain.startsWith('contribuinte') || domain.startsWith('localhost');
        }
    }
})();
