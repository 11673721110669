(function () {
    'use strict';
    angular
        .module('sfzPortalContribuinteGatewayApp')
        .factory('News', News);

    News.$inject = ['$resource'];

    function News($resource) {
        var resourceUrl = '/sefaz-noticias/noticias?format=json';

        var interceptor = {
            response: function(response) {
                return response.data;
            },
            responseError: function(rejection) {
                console.error('Erro ao carregar notícias:', rejection);
                throw rejection;
            }
        };

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: false, // Mudança aqui
                interceptor: interceptor
            }
        });
    }
})();
