(function () {
    "use strict";

    angular
        .module("sfzPortalContribuinteGatewayApp")
        .controller("AlertModalController", AlertModalController);

    AlertModalController.$inject = ["$scope", "$uibModalInstance", "$window"];

    function AlertModalController($scope, $uibModalInstance, $window) {
        $scope.ok = function () {
            $uibModalInstance.close();
            $window.location.href = "/mensageria/#";
        };
    }
})();
