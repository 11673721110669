(function () {
    'use strict';
    angular
        .module('sfzPortalContribuinteGatewayApp')
        .factory('MensageriaService', MensageriaService);

    MensageriaService.$inject = ['$resource'];

    function MensageriaService ($resource) {
        return $resource('', {}, {
            'consultarMensagemNaoLidas': {
                method: 'GET',
                url: 'api/mensageria/caixa-postal',
                isArray: true,
                interceptor: {
                    response: function (response) {
                        return response.data;
                    }
                }
            },
            'getNivelCriticidade': {
                method: 'GET',
                url: 'api/mensageria/criticidade-bloqueio',
                isArray: false,
                interceptor: {
                    response: function(response) {
                        return response.data;
                    }
                }
            }
        });
    }
})();
