(function() {
    'use strict';

    angular
        .module('sfzPortalContribuinteGatewayApp')
        .controller('ForgotPassController', ForgotPassController);

    ForgotPassController.$inject = ['$filter', '$interval'];

    function ForgotPassController ($filter, $interval) {
        var vm = this;

        //vm.gatewayRoutes = null;
        //vm.refresh = refresh;
        vm.updatingRoutes = null;
        vm.documento  = null;
        vm.processDocument = processDocument ;

        //vm.refresh();

        function processDocument (event) {
            event.preventDefault() ;

        }
    }

})();
