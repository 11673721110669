(function () {
    "use strict";

    angular
        .module("sfzPortalContribuinteGatewayApp")
        .controller("NewsController", NewsController);

    NewsController.$inject = ["NewsDetails", "$stateParams", "$sce"];

    function NewsController(NewsDetails, $stateParams, $sce) {
        var vm = this;

        vm.news = {};
        var newsId = $stateParams.id;
        vm.baseUrl = "https://www.sefaz.al.gov.br";

        getNews();
        vm.sanitize = function (html) {
            const div = document.createElement("div");
            div.innerHTML = html;
            const imgs = div.querySelectorAll(".jg_row");
            for (var i = 0; i < imgs.length; i++) {
                imgs[i].remove();
            }
            return $sce.trustAsHtml(div.innerHTML);
        };

        vm.timeSince = function (date) {
            var seconds = Math.floor((new Date() - new Date(date)) / 1000);

            var interval = seconds / 31536000;

            if (interval <= 0) {
                return "0 segundo(s)";
            }
            if (interval > 1) {
                return Math.floor(interval) + " ano(s)";
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                return Math.floor(interval) + " mes(es)";
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + " dia(s)";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + " hora(s)";
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + " minuto(s)";
            }
            return Math.floor(seconds) + " segundo(s)";
        };

        vm.formataData = function (data) {
            return new Date(data).toLocaleDateString("pt-BR");
        };

        function getNews() {
            NewsDetails.query({ id: newsId })
                .$promise.then(function (response) {
                    vm.news = response.item;
                })
                .catch(function (error) {
                    console.error("Erro ao carregar notícia:", error);
                });
        }
    }
})();
